body {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background: radial-gradient(circle, rgba(5,5,5,1) 21%, rgba(37,33,33,1) 93%);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.container {
  margin: 2rem auto;
  width: 60px;
  height: 60px;
  display: flex;
  place-content: center;
}

.item {
  overflow: visible;
  stroke: #09f;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}

.intro {
  margin: 8rem auto 1rem auto;
  width: 60%;
  color: white;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
}

.intro span {
  color: #09f;
  font-weight: 700;
}

.intro a {
  font-weight: 600;
  text-decoration: none;
  color: #09f;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: white;
  padding: 0.7rem 3rem;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 0px rgb(5, 5, 5, 0.2);
  transition: all .2s ease-in-out;
}

.intro a:hover{
  color: white;
  background-color: #09f;
  box-shadow: 0px 0px 8px rgba(255,255,255, 0.4);
}

.intro i {
  color: #09f;
  margin-left: 0.5rem;
  transition: all .2s ease-in-out;
}

.intro a:hover i{
  color: white;
}

.about {
  margin: 6rem auto 0 auto;
  width: 65%;
  line-height: 1.7;
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
}

.mehdi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.about span {
  color: #09f;
  font-weight: 700;
}

.about img {
  width: 25%;
  border-radius: 100%;
  margin-right: 3rem;
}

.about a {
  font-weight: 600;
  text-decoration: none;
  color: #09f;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: white;
  padding: 0.7rem 3rem;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 0px rgb(5, 5, 5, 0.2);
  transition: all .2s ease-in-out;
}

.about a:hover{
  color: white;
  background-color: #09f;
  box-shadow: 0px 0px 8px rgba(255,255,255, 0.4);
}

.about i {
  color: #09f;
  margin-left: 0.5rem;
  transition: all .2s ease-in-out;
}

.about a:hover i{
  color: white;
}

.portfolio {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  cursor: pointer;
  position: relative;
  height: fit-content;
  margin-top: 3rem;
  width: 30%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 0.5rem 0;
  transition: all .2s ease-in-out;
}

.card img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.text {
 text-align: center;
 font-size: 0.8rem;
}

.card:hover {
  transform: scale(1.05);
}

.card a {
  display: inline;
  margin-left: 6px;
  text-decoration: none;
  color: #09f;
  transition: all .2s ease-in-out;
}

.card h2 {
  color: #09f;
}

.card a:hover {
  transform: scale(1.1);
}